import React from 'react'
import quote from '../../../src/assets/svg/quote.svg'

export default function Qoute() {
    return (
        <div>
            <section class="page-section" id="quote">
                <img src={quote} alt="..."></img>
            </section>
        </div>
    )
}