import React from 'react'
import one from '../../assets/img/services/1.jpg';
import two from '../../assets/img/services/2.jpg';

export default function Services() {
    return (
        <div>
            <section class="page-section" id="services">
                <div class="container">
                    <div class="text-center">
                        <h2 class="section-heading text-uppercase">Services</h2>
                        <h3 class="section-subheading text-muted"></h3>
                    </div>
                    <div class="row text-center services-center">
                        <div class="col-md-5">
                            <span class="fa-stack fa-4x">
                                <img class="service-img" src={one}/>
                            </span>
                            <h4 class="my-3">Corporate Events</h4>
                            <p class="text-muted">Imagik Events is a prime provider of planning and management services for corporate events in Philippines. We have a rich experience of working with corporations and companies from a wide range of industries Whether it’s a seminar aimed at further professional development, a gathering to celebrate important mile-stones or a team-building event to foster stronger bonds between colleagues, we are here to make it achieve its goal.</p>
                        </div>
                        <div class="col-md-5">
                            <span class="fa-stack fa-4x">
                                <img class="service-img" src={two}/>
                            </span>
                            <h4 class="my-3">Social Events</h4>
                            <p class="text-muted">Trust us to take care of all the logistics of your event! We care about making your event the best possible experience for everyone involved. We take care of all the small, technical details.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}