import React from 'react'
import one from '../../assets/img/team/1.png'
import two from '../../assets/img/team/2.png'
import three from '../../assets/img/team/3.jpg'

export default function Team() {
    return (
        <div>
            <section class="page-section bg-light" id="team">
                <div class="container">
                    <div class="text-center">
                        <h2 class="section-heading text-uppercase">Our Amazing Team</h2>
                        <h3 class="section-subheading text-muted"></h3>
                    </div>
                    <div class="row">
                        <div class="col-lg"></div>
                        <div class="col-lg">
                            <div class="team-member">
                                <img class="mx-auto rounded-circle" src={one} alt="..." />
                                <h4>Neil Maristela</h4>
                                <p class="text-muted">Creative Director</p>
                                <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-instagram"></i></a>
                                <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                            </div>
                        </div>
                        <div class="col-lg">
                            <div class="team-member">
                                <img class="mx-auto rounded-circle" src={two} alt="..." />
                                <h4>Kathy Guillermo</h4>
                                <p class="text-muted">CEO</p>
                                <a class="btn btn-dark btn-social mx-2" href="#!" aria-label="Diana Petersen Twitter Profile"><i class="fab fa-instagram"></i></a>
                                <a class="btn btn-dark btn-social mx-2" href="#!" aria-label="Diana Petersen Facebook Profile"><i class="fab fa-facebook-f"></i></a>
                            </div>
                        </div>
                        <div class="col-lg"></div>
                        {/* <div class="col-lg-4">
                            <div class="team-member">
                                <img class="mx-auto rounded-circle" src={three} alt="..." />
                                <h4>Larry Parker</h4>
                                <p class="text-muted">Lead Developer</p>
                                <a class="btn btn-dark btn-social mx-2" href="#!" aria-label="Larry Parker Twitter Profile"><i class="fab fa-twitter"></i></a>
                                <a class="btn btn-dark btn-social mx-2" href="#!" aria-label="Larry Parker Facebook Profile"><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-dark btn-social mx-2" href="#!" aria-label="Larry Parker LinkedIn Profile"><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div> */}
                    </div>
                    <div class="row">
                        <div class="col-lg-8 mx-auto text-center"><p class="large text-muted">Kat and Neil lead a small team of creative men and women committed to walk with you in the magical journey of your milestones.</p></div>
                    </div>
                </div>
            </section>
        </div>
    )
}